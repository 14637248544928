/* Main */
$bg: #f7f6fe;
$rosatom-blue: #025ea1;
$rosatom-green: #32ca72;
$rosatom-error: #e64646;
$rosatom-deep-blue: #003274;
$rosatom-very-light-gray: #e6eff6;
$rosatom-very-light-gray-selected: #e1e9f1;
$rosatom-light-blue: #309bec;
$rosatom-light: #118eff;
$rosatom-dark-gray: #5f6062;
$primary: #2d9cdb;
$primary-active: #66b7e5;
$secondary: #b8c1cc;
$secondary-active: #cdd3db;

/* Other */
$green: #52c41a;
$light-blue: #8191a7;
$lighten-blue: #56ccf2;
$blue: #4392ce;
$yellow: #f0a600;
$orange: #ff4a05;
$orange2: #EE6C23;
$gray6: #D9DBE9;
$gray5: #BCBED2;
$gray4: #6e7191;
$gray3: #333333;
$gray2: #6d7885;
$gray: #7f7f7f;
$lighten-gray: #f2f2f2;
$light-gray: #e7eaed;
$dark-gray: #818c99;
$light-gray-s: #A0A3BD;
$white: #ffffff;
$dark-blue-s: #262338;
$black: #000000;
$red: #ff1837;
$light-red: #faebeb;
$light-red-selected: #efe1e1;
$purple: #ac97cd;

